import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {BASE_URL} from '@core/services/api.service';
import {AuthService} from '../auth.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private meDataSubject = new BehaviorSubject<any>(null);
  public me$ = this.meDataSubject.asObservable();
  private authService: AuthService;

  constructor(private http: HttpClient, private injector: Injector) {
    setTimeout(() => this.authService = this.injector.get(AuthService));
  }

  loadMeData(): Observable<any> {
    return this.http.get(`${BASE_URL}/api/v1/users/me`).pipe(
      tap(res => {
        const meData = res['data'];
        this.meDataSubject.next(meData);
      }),
      catchError(error => {
        this.showErrorSwal();
        this.meDataSubject.next(null);
        this.authService.logout();
        throw error;
      })
    );
  }

  getMeData(): any {
    return this.meDataSubject.value;
  }

  showErrorSwal() {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Server bilan aloqa uzildi',
      text: 'Iltimos keyinroq qayta urinib ko\'ring',
      showConfirmButton: true,
      confirmButtonColor: '#EA5455',
      confirmButtonText: 'Yopish',
    });
  }
}
