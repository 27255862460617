import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';

export const BASE_URL = environment.apiUrl;
export const CHAT_URL = environment.chatUrl;

export const LANG_RU = 'ru';
export const LANG_UZ = 'uz';
export const LANG_CRL = 'crl';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public http: HttpClient,
    public translateService: TranslateService
  ) {
  }

  get(prefix: string, params: any = {}) {
  }

  post(prefix: string, body: any = {}) {
  }

  put(prefix: string, body: any = {}) {
  }

  delete(prefix: string, params: any = {}): any {
  }

}
