import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, tap} from 'rxjs';
import {User} from '@app/modules/pages/login/helpers/user';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ELocalStorage} from '@core/enums/LocalStorage.enum';
import {BASE_URL} from '@core/services/api.service';
import {MeService} from './helpers/me.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser = this.currentUserSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private meService: MeService
  ) {
    if (this.isLoggedIn()) {
      this.getMe();
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getMe() {
    this.meService.me$.subscribe(meData => {
      this.currentUserSubject.next(meData);
    });
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${BASE_URL}/api/v1/user/login`, {email, password}).pipe(tap(
      (res: any) => {
        sessionStorage.setItem(ELocalStorage.ACCESS_TOKEN, res.token);

        this.getMe();
        return of(res);
      },
      (error) => {
        return of(error);
      }
    ));
  }

  public isLoggedIn(): boolean {
    const token = sessionStorage.getItem(ELocalStorage.ACCESS_TOKEN);
    return !!token;
  }

  logout() {
    sessionStorage.removeItem(ELocalStorage.ACCESS_TOKEN);
    sessionStorage.removeItem('parentCode');
    sessionStorage.removeItem('coato_code');
    sessionStorage.removeItem('coato_name');
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem('currentPage');
    sessionStorage.removeItem('call-details');

    this.router.navigate(['login']);
  }
}
